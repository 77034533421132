<template>
  <SubpageLayout :to="{ name: 'home' }" :title="$t('tracking.header')">
    <BlockButton
      v-for="(provider, key) in trackingProviders"
      :key="key"
      as="a"
      :href="provider.url"
      :title="provider.name"
      :subtitle="provider.description"
      :logo="provider.logo"
    >
      <template #icon="{ className }">
        <ExternalLinkIcon :class="className" />
      </template>
    </BlockButton>

    <SectionSeparator />

    <SectionHeader :title="$t('tracking.faq.header')" :subtitle="$t('tracking.faq.subheader')" />

    <FAQ :items="faq"></FAQ>

    <HelpCenterButton />
  </SubpageLayout>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import SubpageLayout from '@/components/SubpageLayout'
import BlockButton from '@/components/BlockButton'
import SectionSeparator from '@/components/SectionSeparator'
import SectionHeader from '@/components/SectionHeader'
import FAQ from '@/components/FAQ'
import ExternalLinkIcon from '@/assets/icons/external-link.svg'
import HelpCenterButton from '@/components/HelpCenterButton'

export default {
  components:{
    BlockButton,
    ExternalLinkIcon,
    FAQ,
    SectionSeparator,
    SectionHeader,
    SubpageLayout,
    HelpCenterButton,
  },

  setup() {
    const store = useStore()

    return {
      faq: computed(() => store.state.trackingFAQ),
      trackingProviders: computed(() => store.state.trackingProviders),
    }
  }
}
</script>